<template>
  <div v-show="visible" class="box notice popup click top-margin-10" @mouseover="mouseOver" @mouseout="mouseOut" @click="onClick">
    <div class="notice-icon" v-if="notification.icon || notification.type === 'chat'">
      <div :class="['game-icon', notification.icon]"></div>
    </div>

    <!-- Avatar/Icon -->
    <img v-if="notification.avatar" :src="avatarUrl(notification.avatar)" class="avatar">
    <span v-else-if="type" :class="['icon', 'right-margin-10', type]"></span>

    <!-- Content -->
    <div class="text wrap truncate" v-html="text"></div>

    <!-- Close Button -->
    <span class="icon close light click right center-self left-margin-10" @click="clear"></span>
  </div>
</template>

<script>
import {avatarUrl} from '../utils';
import {notification} from '../mixins';

// Include visibility API.
let Visibility;
if (typeof window !== 'undefined') {
  Visibility = require('visibilityjs');
}

export default {
  name: 'notice-popup',
  mixins: [notification],
  data() {
    return {
      timer: null,
      visible: false,
    };
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    avatarUrl,
    clear(isTimer) {
      this.$store.commit('remNotificationFromQueue', this.notification._id);
      if (this.notification.type !== 'chat' && isTimer !== true) {
        this.$store.dispatch('markNotificationRead', this.notification._id);
        this.$store.commit('incToolbarNotifications', -1);
        this.$store.commit('incGameNotifications', {notification: this.notification, inc: -1});
      }
      this.mouseOver();
    },
    startTimer() {
      if (Visibility.state() === 'visible') {
        this.visible = true;
        this.timer = setTimeout(() => {
          this.clear(true);
        }, 5000);
      } else {
        this.visible = false;
        this.clear(true);
        this.showDesktopNotification();
      }
    },
    mouseOver() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    mouseOut() {
      if (!this.timer) {
        this.startTimer();
      }
    },
    onClick(event) {
      if (!event.target.className.match('icon close')) {
        this.action();
        this.clear();
      }
    },
    showDesktopNotification() {
      if ('Notification' in window && Notification.permission === 'granted') {
        const desktopNotification = new Notification(`Message from ${this.notification.user}`, {
          body: this.notification.text.replace('[[USER]]<br>', '').replaceAll('<b>', '').replaceAll('</b>', ''),
          icon: avatarUrl(this.notification.avatar), // Path to your notification icon
        });

        // Handle notification click event, e.g., focus the chat window
        desktopNotification.onclick = () => {
          window.focus();
          this.action();
          this.clear();
        };
      }
    },
  },
};
</script>
